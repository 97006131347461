<template>
  <b-col
    id="data-produk"
    class="pl-0 pr-0"
  >
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <b-row class="d-flex justify-content-end align-items-center mt-2 mr-50 wrapper__filter__data__product">
              <b-col
                md="3"
                class="text-center"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchProduct"
                    placeholder="Example"
                    @input="getProduct"
                  />
                </b-input-group>
              </b-col>
              <b-col md="auto">
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  right
                  no-caret
                  variant="primary"
                >
                  <template
                    #button-content
                  >
                    <feather-icon icon="SlidersIcon" />
                  </template>
                  <b-dropdown-form
                    style="width: 417px;"
                  >
                    <b-form @submit.prevent>
                      <b-row>
                        <b-col
                          cols="12"
                          class="ml-50"
                        >
                          <b-form-group
                            label="Stok"
                          >
                            <div class="d-flex justify-content-center align-items-center">
                              <b-form-input
                                v-model="stockFrom"
                                class=""
                              />
                              <b-button
                                class="btn-icon"
                                variant="flat-dark"
                                disabled
                              >
                                <feather-icon
                                  icon="MinusIcon"
                                />
                              </b-button>
                              <b-form-input
                                v-model="stockTo"
                                class="mr-1"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          class="ml-50 mt-50"
                        >
                          <b-form-group
                            label="Terjual"
                          >
                            <div class="d-flex justify-content-center align-items-center">
                              <b-form-input
                                v-model="soldFrom"
                                class=""
                              />
                              <b-button
                                class="btn-icon"
                                variant="flat-dark"
                                disabled
                              >
                                <feather-icon
                                  icon="MinusIcon"
                                />
                              </b-button>
                              <b-form-input
                                v-model="soldTo"
                                class="mr-1"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- submit and reset -->
                        <b-col
                          cols="12"
                          class="ml-50 mt-1"
                        >
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            variant="outline-primary"
                            class="mr-1"
                            @click="resetFilter"
                          >
                            Reset
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            @click="getProduct"
                          >
                            Terapkan
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-dropdown-form>
                </b-dropdown>
              </b-col>
            </b-row>

            <!-- Mobile -->
            <b-row class="d-flex justify-content-end align-items-center mt-2 mx-50 wrapper__filter__data__product__mobile">
              <b-col
                cols="8"
                class="text-center"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchProduct"
                    placeholder="Example"
                    @input="getProduct"
                  />
                </b-input-group>
              </b-col>
              <b-col cols="4">
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  right
                  no-caret
                  variant="primary"
                >
                  <template
                    #button-content
                  >
                    <feather-icon icon="SlidersIcon" />
                  </template>
                  <b-dropdown-form
                    class="wrapper__form__filter__data__product__mobile"
                    style="width: 417px;"
                  >
                    <b-form @submit.prevent>
                      <b-row>
                        <b-col
                          cols="12"
                          class="ml-50"
                        >
                          <b-form-group
                            label="Stok"
                          >
                            <div class="d-flex justify-content-center align-items-center">
                              <b-form-input
                                v-model="stockFrom"
                                class=""
                              />
                              <b-button
                                class="btn-icon"
                                variant="flat-dark"
                                disabled
                              >
                                <feather-icon
                                  icon="MinusIcon"
                                />
                              </b-button>
                              <b-form-input
                                v-model="stockTo"
                                class="mr-1"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          class="ml-50 mt-50"
                        >
                          <b-form-group
                            label="Terjual"
                          >
                            <div class="d-flex justify-content-center align-items-center">
                              <b-form-input
                                v-model="soldFrom"
                                class=""
                              />
                              <b-button
                                class="btn-icon"
                                variant="flat-dark"
                                disabled
                              >
                                <feather-icon
                                  icon="MinusIcon"
                                />
                              </b-button>
                              <b-form-input
                                v-model="soldTo"
                                class="mr-1"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- submit and reset -->
                        <b-col
                          cols="12"
                          class="ml-50 mt-1"
                        >
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            variant="outline-primary"
                            class="mr-1"
                            @click="resetFilter"
                          >
                            Reset
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            @click="getProduct"
                          >
                            Terapkan
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-dropdown-form>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-row class="mt-1">
      <b-col
        cols="12"
        class=""
      >
        <b-overlay
          variant="light"
          :show="loading"
          spinner-variant="primary"
          blur="0"
          opacity="0.5"
          rounded="sm"
        >
          <BTable
            :items="items"
            :fields="fields"
            show-empty
            empty-text="Tidak ada data untuk ditampilkan."
            responsive
            class="mt-1"
          >
            <template #cell(product_name)="data">
              <div style="min-width: 20px !important">
                <div class="d-flex">
                  <b-img
                    v-if="data.item.product_image[0] !== undefined"
                    :src="data.item.product_image[0].images_path"
                    fluid
                    class="image-product"
                  />
                  <b-img
                    v-else
                    :src="require('@/assets/images/avatars/image-null.png')"
                    fluid
                    width="50"
                    class="image-product"
                  />
                  <div class="ml-1">
                    <div class="font-medium">
                      {{ data.item.product_name }}
                    </div>
                    <div class="text-primary text-[12px]">
                      SKU: {{ data.item.sku }}
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template #cell(variants)="data">
              <div style="min-width: 20px !important">
                <div v-if="data.item.variants[0] !== undefined">
                  <div
                    v-for="(itemsVariant, indexVariant) in data.item.variants.slice(0,3)"
                    :key="indexVariant+1"
                  >
                    <p>
                      {{ itemsVariant.variant }}
                    </p>
                  </div>
                  <b-collapse
                    :id="`collapse-${String(data.item.product_id)}`"
                    class=""
                  >
                    <div
                      v-for="(itemsVariant, indexVariant) in data.item.variants.slice(3,data.item.variants.length)"
                      :key="indexVariant+1"
                    >
                      <p>
                        {{ itemsVariant.variant }}
                      </p>
                    </div>

                  </b-collapse>
                </div>
                <div v-else>
                  -
                </div>
              </div>
            </template>

            <template #cell(price)="data">
              <div style="min-width: 20px !important">
                <div v-if="data.item.variants[0] !== undefined">
                  <div
                    v-for="(itemsVariant, indexVariant) in data.item.variants.slice(0,3)"
                    :key="indexVariant+1"
                  >
                    <p>
                      Rp {{ formatPrice(itemsVariant.price) }}
                    </p>
                  </div>
                  <b-collapse
                    :id="`collapse-${String(data.item.product_id)}`"
                    class=""
                  >
                    <div
                      v-for="(itemsVariant, indexVariant) in data.item.variants.slice(3,data.item.variants.length)"
                      :key="indexVariant+1"
                    >
                      <p>
                        Rp {{ formatPrice(itemsVariant.price) }}
                      </p>
                    </div>

                  </b-collapse>
                </div>
                <div v-else>
                  Rp {{ formatPrice(data.item.price) }}
                </div>
              </div>
            </template>

            <template #cell(stock)="data">
              <div style="min-width: 20px !important">
                <div v-if="data.item.variants[0] !== undefined">
                  <div
                    v-for="(itemsVariant, indexVariant) in data.item.variants.slice(0,3)"
                    :key="indexVariant+1"
                  >
                    <p>
                      {{ itemsVariant.stock }}
                    </p>
                  </div>
                  <b-collapse
                    :id="`collapse-${String(data.item.product_id)}`"
                    class=""
                  >
                    <div
                      v-for="(itemsVariant, indexVariant) in data.item.variants.slice(3,data.item.variants.length)"
                      :key="indexVariant+1"
                    >
                      <p>
                        {{ itemsVariant.stock }}
                      </p>
                    </div>

                  </b-collapse>
                </div>
                <div v-else>
                  {{ data.item.stock }}
                </div>
              </div>
            </template>

            <template #cell(sold)="data">
              <div style="min-width: 20px !important">
                <div v-if="data.item.variants[0]!== undefined">
                  <div
                    v-for="(itemsVariant, indexVariant) in data.item.variants.slice(0,3)"
                    :key="indexVariant+1"
                  >
                    <p>
                      {{ itemsVariant.sold }}
                    </p>
                  </div>
                  <b-collapse
                    :id="`collapse-${String(data.item.product_id)}`"
                    class=""
                  >
                    <div
                      v-for="(itemsVariant, indexVariant) in data.item.variants.slice(3,data.item.variants.length)"
                      :key="indexVariant+1"
                    >
                      <p>
                        {{ itemsVariant.sold }}
                      </p>
                    </div>

                  </b-collapse>
                </div>
                <div v-else>
                  {{ data.item.sold }}
                </div>
              </div>
            </template>

            <template #cell(action)="data">
              <div style="min-width: 20px !important">
                <div>
                  <b-button
                    v-if="data.item.is_bundling === '0'"
                    v-b-tooltip.hover.top="'Edit'"
                    class="btn-icon"
                    size="sm"
                    variant="flat-dark"
                    @click="checkIsBundlingComponents('edit', 'nonBundling', data.item.product_id)"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/edit.svg"
                      alt="edit"
                      width="18"
                    >
                  </b-button>
                  <b-button
                    v-if="data.item.is_bundling === '1'"
                    v-b-tooltip.hover.top="'Edit'"
                    class="btn-icon"
                    size="sm"
                    variant="flat-dark"
                    @click="checkBundling('edit', data.item.product_id)"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/edit.svg"
                      alt="edit"
                      width="18"
                    >
                  </b-button>
                  <b-button
                    v-if="data.item.is_bundling === '0'"
                    v-b-tooltip.hover.top="'Bundling'"
                    class="btn-icon"
                    size="sm"
                    variant="flat-dark"
                    tag="router-link"
                    :to="{ name: $route.meta.routeBundling, params: { product_id: data.item.product_id } }"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/gift.svg"
                      alt="bundling"
                      width="18"
                    >
                  </b-button>
                  <b-button
                    v-if="data.item.is_bundling === '0'"
                    v-b-tooltip.hover.top="'Hapus'"
                    class="btn-icon"
                    size="sm"
                    variant="flat-dark"
                    @click="checkProduct('delete', data.item.product_id)"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                      alt="trash"
                      width="18"
                    >
                  </b-button>
                  <b-button
                    v-if="data.item.is_bundling === '1'"
                    v-b-tooltip.hover.top="'Hapus'"
                    class="btn-icon"
                    size="sm"
                    variant="flat-dark"
                    @click="checkBundling('delete', data.item.product_id)"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                      alt="trash"
                      width="18"
                    >
                  </b-button>
                </div>
                <div v-if="data.item.variants.length > 3">
                  <b-button
                    v-b-toggle="`collapse-${String(data.item.product_id)}`"
                    variant="flat-dark"
                    size="sm"
                  >
                    <span class="when-opened">
                      Tutup
                    </span>
                    <span class="when-closed">
                      Tampilkan variasi lainnya
                    </span>
                    <feather-icon
                      icon="ChevronDownIcon"
                      class="when-closed"
                    />
                    <feather-icon
                      icon="ChevronUpIcon"
                      class="when-opened"
                    />
                  </b-button>
                </div>
              </div>
            </template>
          </BTable>
        </b-overlay>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BImg,
  BAvatar,
  VBPopover,
  BOverlay,
  VBToggle,
  BModal,
  BDropdown,
  BDropdownForm,
  BTable,
  BContainer,
  BCollapse,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { heightTransition } from '@core/mixins/ui/transition'
import { komshipAxiosIns } from '@/libs/axios'
import secureLs from '@/libs/secureLs'
import { fieldsBundling } from './config'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BImg,
    BForm,
    BOverlay,
    BDropdown,
    BDropdownForm,
    BTable,
    // BContainer,
    BCollapse,
  },
  directives: {
    'b-popover': VBPopover,
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      userData: secureLs.getItem('userData'),

      defaultFilter: 0,
      headVariant: null,
      idDelete: '',
      loading: false,
      variantFieldsTable: [
        {
          key: 'variant',
          label: 'Variasi',
          thClass: 'border-0 bg-white',
          tdClass: 'border-0',
        },
      ],
      variantItems: [[]],
      variantItemsExpand: [[]],
      variantData: [],
      fields: [
        {
          key: 'product_name',
          label: 'Nama Produk',
          class: 'p-1',
          thStyle: {
            fontSize: '14px',
            color: '#6E6B7B',
            textTransform: 'capitalize',
          },
          tdClass: 'flex align-top',
        },
        {
          key: 'variants',
          label: 'Variasi',
          class: 'p-1',
          thStyle: {
            fontSize: '14px',
            color: '#6E6B7B',
            textTransform: 'capitalize',
          },
          tdClass: 'align-top',
        },
        {
          key: 'price',
          label: 'Harga',
          class: 'p-1',
          thStyle: {
            fontSize: '14px',
            color: '#6E6B7B',
            textTransform: 'capitalize',
          },
          tdClass: 'align-top',
        },
        {
          key: 'stock',
          label: 'Stock',
          class: 'p-1',
          thStyle: {
            fontSize: '14px',
            color: '#6E6B7B',
            textTransform: 'capitalize',
          },
          tdClass: 'align-top',
        },
        {
          key: 'sold',
          label: 'Terjual',
          class: 'p-1',
          thStyle: {
            fontSize: '14px',
            color: '#6E6B7B',
            textTransform: 'capitalize',
          },
          tdClass: 'align-top',
        },
        {
          key: 'action',
          label: 'Aksi',
          class: 'p-1',
          thStyle: {
            fontSize: '14px',
            color: '#6E6B7B',
            textTransform: 'capitalize',
            textAlign: 'center',
          },
          tdClass: 'align-top text-center',
        },
      ],
      fieldsBundling,
      items: [],
      imageFileProduct: null,
      searchProduct: '',
      expandCollapseIsActive: false,
      // Filter
      name: '',
      stockFrom: '',
      stockTo: '',
      soldFrom: '',
      soldTo: '',
      limit: 50,
      offset: 0,
      lastOrderProduct: false,
    }
  },
  mounted() {
    this.fetchProduct()
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('data-produk').offsetHeight && !this.loading) {
        this.getNextProduct()
      }
    }
  },
  methods: {
    fetchProduct() {
      this.offset = 0
      this.loading = true
      const params = {
        status: 1,
        limits: this.limit,
        offset: this.offset,
      }
      if (this.searchProduct) Object.assign(params, { name: this.searchProduct })
      if (this.soldFrom) Object.assign(params, { soldFrom: this.soldFrom })
      if (this.soldTo) Object.assign(params, { soldTo: this.soldTo })
      if (this.stockFrom) Object.assign(params, { stockFrom: this.stockFrom })
      if (this.stockTo) Object.assign(params, { stockTo: this.stockTo })
      komshipAxiosIns.get('/v4/product', {
        params,
      }, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        const { data } = response.data
        this.items = data
        console.log(data)
        if (data.length < this.limit) {
          this.lastOrderProduct = true
        } else {
          this.lastOrderProduct = false
        }
        this.offset = data.length
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal me-load produk, silahkan coba lagi!',
            variant: 'danger',
          },
        })
      })
    },
    getProduct: _.debounce(function () {
      this.loading = true
      this.limit = 50
      this.offset = 0
      const params = {
        status: 1,
        limits: this.limit,
        offset: this.offset,
      }
      if (this.searchProduct) Object.assign(params, { name: this.searchProduct })
      if (this.soldFrom) Object.assign(params, { soldFrom: this.soldFrom })
      if (this.soldTo) Object.assign(params, { soldTo: this.soldTo })
      if (this.stockFrom) Object.assign(params, { stockFrom: this.stockFrom })
      if (this.stockTo) Object.assign(params, { stockTo: this.stockTo })
      komshipAxiosIns.get('/v4/product', {
        params,
      }, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        const { data } = response.data
        this.items = data
        if (data.length < this.limit) {
          this.lastOrderProduct = true
        } else {
          this.lastOrderProduct = false
        }
        this.offset = data.length
        this.loading = false
        // return this.variantData
      }).catch(() => {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal me-load produk, silahkan coba lagi!',
            variant: 'danger',
          },
        })
      })
    }, 1000),
    getNextProduct() {
      if (!this.lastOrderProduct) {
        this.loading = true
        const params = {
          status: 1,
          limits: this.limit,
          offset: this.offset,
        }
        if (this.searchProduct) Object.assign(params, { name: this.searchProduct })
        if (this.soldFrom) Object.assign(params, { soldFrom: this.soldFrom })
        if (this.soldTo) Object.assign(params, { soldTo: this.soldTo })
        if (this.stockFrom) Object.assign(params, { stockFrom: this.stockFrom })
        if (this.stockTo) Object.assign(params, { stockTo: this.stockTo })
        komshipAxiosIns.get('/v4/product', {
          params,
        }, {
          headers: { Authorization: `Bearer ${useJwt.getToken()}` },
        }).then(response => {
          const { data } = response.data
          this.items.push(...data)
          this.offset += data.length
          this.loading = false
          if (data.length < this.limit) {
            this.lastOrderProduct = true
          } else {
            this.lastOrderProduct = false
          }
          return this.items
        }).catch(() => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal me-load produk, silahkan coba lagi!',
              variant: 'danger',
            },
          })
        })
      }
    },
    deleteProduct(id) {
      komshipAxiosIns.delete(`/v1/product/delete/${id}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            text: 'Success hapus produk',
            variant: 'success',
          },
        })
        this.getProduct()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal hapus produk, silahkan coba lagi!',
            variant: 'danger',
          },
        })
      })
    },
    deleteBundling(id) {
      komshipAxiosIns.delete(`/v1/product/bundling/delete/${id}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            text: 'Success hapus produk',
            variant: 'success',
          },
        })
        this.getProduct()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal hapus produk, silahkan coba lagi!',
            variant: 'danger',
          },
        })
      })
    },
    resetFilter() {
      this.name = ''
      this.stockFrom = ''
      this.stockTo = ''
      this.soldFrom = ''
      this.soldTo = ''
      this.getProduct()
    },
    formatPrice(value) {
      const val = value
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    checkProduct(method, id) {
      komshipAxiosIns.get(`/v1/product/detail/${id}`).then(res => {
        const { is_kompack } = res.data.data
        // eslint-disable-next-line camelcase
        if (is_kompack === 1) {
          this.$swal({
            title: 'Tidak dapat hapus produk',
            text: 'Maaf, produk tidak dapat dihapus karena terhubung dengan Gudang kompack',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Oke',
            customClass: {
              icon: 'border-0 mb-[16px]',
              cancelButton: 'btn btn-primary w-full',
            },
            buttonsStyling: false,
          })
        } else {
          this.checkIsBundlingComponents(method, 'nonBundling', id)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    checkBundling(method, id) {
      komshipAxiosIns.get(`/v1/product/bundling/check-kompack?product_id=${id}`).then(res => {
        const { is_kompack } = res.data.data
        // eslint-disable-next-line camelcase
        if (is_kompack === 0) {
          if (method === 'edit') {
            this.$router.push({ name: this.$route.meta.routeEditBundling, params: { product_id: id } })
          } else {
            this.confirmDelete('bundling', id)
          }
        } else if (method === 'edit') {
          this.$swal({
            title: 'Tidak dapat edit bundling',
            text: 'Maaf, produk bundlingmu terhubung dengan gudang kompack. kamu tidak bisa melakukan edit produk bundling',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Oke',
            customClass: {
              icon: 'border-0 mb-[16px]',
              cancelButton: 'btn btn-primary w-full',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Tidak dapat hapus produk',
            text: 'Maaf, produk bundlingmu terhubung dengan gudang kompack. kamu tidak bisa melakukan hapus produk bundling',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Oke',
            customClass: {
              icon: 'border-0 mb-[16px]',
              cancelButton: 'btn btn-primary w-full',
            },
            buttonsStyling: false,
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    checkIsBundlingComponents(method, bundling, id) {
      komshipAxiosIns.get(`/v1/product/bundling/check-related/${id}`).then(res => {
        const { is_bundling_related } = res.data.data
        // eslint-disable-next-line camelcase
        if (is_bundling_related === 0) {
          if (method === 'edit') {
            if (bundling === 'nonBundling') {
              this.$router.push({ name: this.$route.meta.routeEdit, params: { product_id: id } })
            } else {
              this.$router.push({ name: this.$route.meta.routeEditBundling, params: { product_id: id } })
            }
          } else if (method === 'delete') {
            if (bundling === 'nonBundling') {
              this.confirmDelete('nonBundling', id)
            } else {
              this.confirmDelete('bundling', id)
            }
          }
        } else if (method === 'edit') {
          this.$swal({
            title: 'Tidak dapat edit bundling',
            text: 'Maaf, produk tidak dapat diedit karena terhubung dengan produk bundling',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Oke',
            customClass: {
              icon: 'border-0 mb-[16px]',
              cancelButton: 'btn btn-primary w-full',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Tidak dapat hapus produk',
            text: 'Maaf, produk tidak dapat dihapus karena terhubung dengan produk bundling',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Oke',
            customClass: {
              icon: 'border-0 mb-[16px]',
              cancelButton: 'btn btn-primary w-full',
            },
            buttonsStyling: false,
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    confirmDelete(bundling, id) {
      this.$swal({
        title: 'Kamu yakin menghapus produk ini?',
        text: 'Produk yang terhapus tidak akan dapat dikembalikan lagi',
        icon: 'warning',
        iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'Batal',
        confirmButtonText: 'Iya',
        reverseButtons: true,
        customClass: {
          icon: 'border-0 mb-[16px]',
          cancelButton: 'btn btn-outline-primary w-[48%] mr-[2%]',
          confirmButton: 'btn btn-primary w-[48%] ml-[2%]',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (bundling === 'nonBundling') {
            this.deleteProduct(id)
          } else if (bundling === 'bundling') {
            this.deleteBundling(id)
          }
        }
      })
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
* {
  font-family: 'Poppins', sans-serif;
}
[dir] .background-table-variant {
  background: #FFF;
}
@media only screen and (max-width: 922px) {
    [dir] .table-list-product {
        display: none;
    }
}
@media only screen and (min-width: 923px) {
    [dir] .table-list-product {
        display: inline-block;
    }
    [dir] .table-list-product-mobile {
        display: none;
    }
}
[dir] .when-closed {
  display: inline-block;
}
[dir] .when-opened {
  display: inline-block;
}
.collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
.image-product {
  object-fit: cover;
  object-position: center center;
  width: 50px!important;
  height: 50px!important;
}
@media only screen and (min-width: 991px) {
  [dir] .wrapper__filter__data__product__mobile {
    display: none!important;
  }
}
@media only screen and (max-width: 990px) {
  [dir] .wrapper__filter__data__product {
    display: none!important;
  }
  [dir] .wrapper__form__filter__data__product__mobile {
    width: 270px!important;
  }
}
</style>
